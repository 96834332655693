import React from 'react'
import { CtaBar, LinkButton, LeshenPhoneCTA } from '@leshen/gatsby-theme-leshen'
import MapPinIcon from '../images/map-pin.svg'

const StateCityCtaBar = ({ spanish }) => {
  return (
    <CtaBar
      backgroundColor="primary"
      textColor="dark"
      sticky
      leftBlock={
        <LeshenPhoneCTA outlined color="dark">
          {spanish ? 'Llama' : 'Call'}
        </LeshenPhoneCTA>
      }
      middleBlock={false}
      leftDivider
      rightBlock={
        <LinkButton outlined color="dark" to="/availability">
          <img
            src={MapPinIcon}
            alt=""
            style={{ verticalAlign: 'middle', marginRight: '8px' }}
          />
          {spanish
            ? 'Consulta disponibilidad en tu área'
            : 'Check Availability'}
        </LinkButton>
      }
    />
  )
}

export default StateCityCtaBar
